<template>
  <div>
    <v-container fluid class="pa-0">
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
            v-if="reporte != 1"
            
          >
          
            <template v-slot:activator="{ on, attrs }">
            <v-autocomplete
             v-model="reporteSelected"
            :items="comboBox"  
            color="white"
            hide-no-data
            hide-selected
            item-text="label"
            item-value="id"
            label="Reportes"
            placeholder="Seleccionar reporte"
            prepend-icon="mdi-database-search" 
            >
            

            </v-autocomplete>
              <v-text-field
                v-model="dateRangeText"
                range
                label="Rango de fechas"
                prepend-icon="mdi-calendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" range no-title scrollable :max="hoy">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="date = []">
                LIMPIAR
              </v-btn>
              <v-btn text color="primary" @click="menu = false">
                CANCELAR
              </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)">
                ACEPTAR
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
          <v-col md="1">
          <v-btn small fab color="red" @click="reporte" :disabled="date[0]===undefined" :loading="loading">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-chip class="ma-2" v-if="equipos_asignados">
          Equipos asignados: {{equipos_asignados}}
        </v-chip>
        <v-chip class="ma-2" v-if="total_utilidad!==undefined">
          Total utilidad: {{total_utilidad}}
        </v-chip>
        <v-chip class="ma-2" v-if="datos">
          Total de cortes: {{datos.length}}
        </v-chip>
      </v-row> -->
      <v-row>
        <v-col >
          <DataTable :value="datos" :filters="filters" :paginator="true" :rows="10" removableSort ref="tablaReporte" :loading="loading" dataKey="id"  class="p-datatable-responsive-demo" @row-select="onRowSelect" @row-unselect="onRowUnselect" :selection.sync="seleccionado" selectionMode="single" :scrollable="true"  style="width: auto" :expandedRows.sync="expandedRows" :rowsPerPageOptions="[10,20,50]" showGridlines responsiveLayout="stack" :resizableColumns="true" columnResizeMode="expand">
          <template #header>
            <span class="p-input-icon-left float-md-left">
                <v-icon style="position:relative; left:35px;top:3px">mdi-magnify</v-icon>
                <InputText v-model="filters['global']" placeholder="Buscar" class="p-inputtext-sm" />
            </span>
            <div style="text-align: right">
              <Button class="btn btn-success" label="Export" @click="exportCSV($event)"><span class="d-none d-sm-none d-md-block">Exportar a Excel</span> <v-icon>mdi-file-excel</v-icon></Button>
            </div>
          </template>
          <template #empty>
              Sin datos encontrados con estos parametros de busqueda.
          </template>
          <template #loading>
              Recolectando los datos del servidor.
          </template> 
          <Column v-for="col of encabezados" :field="col.value" :header="col.text" :key="col.value" :sortable="true" header-style="width: 150px;white-space: nowrap; text-overflow: ellipsis; overflow: hidden;" body-style="white-space: nowrap; text-overflow: ellipsis; overflow: auto;"  >
            
             <template #editor="slotProps">
                <InputText v-model="slotProps.data[slotProps.column.field]" />
            </template>
          </Column> 
          
        </DataTable>
        </v-col>
      </v-row>
    </v-container>
     
  </div>
</template>
<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import Servicio from '../../service/apis' 
export default {
  name:'Reportes',
  components: {
    'DataTable': DataTable,
    'Column': Column,
    'InputText': InputText 
  },
 
  data() {
    return{
      modal:false,
      editingRows: [],
      expandedRows: [],
      seleccionado: {},
      clon:{},
      comboBox:[{id:1,label:'Reporte Envios Mexico'},{id:2,label:'Reporte Envios Hemodialisis'},{id:3,label:'Reporte Envios Salvador'}   ],
      select: null,
      inicio: null,
      fin: null,
      date: [],
      reporteSelected:0,
      hoy: new Date().toISOString().substr(0, 10),
      menu:false,
      route_id:{id:null},
      maquina:null,
      tienda:null,
      rutero:null,
      filters: {},
      loading: false,
      equipos_asignados:null,
      total_utilidad:null,
      rutas:[
        {
          name:'rGeneral',
          tittle: 'General'
        },
        {
          name:'rCortes',
          tittle: 'Cortes'
        },
        {
          name:'rGastos',
          tittle: 'Gastos'
        },
      ],
      search: '',
      encabezados: [
        {
          text: 'Reporte',
          align: 'start',
          filterable: false,
          value: 'created_at',
        }
      ],
      datos: [],
      filtros: {routes:[],game_machines:[],users:[],point_of_sales:[]}
    }
  },
  computed: {
    dateRangeText() {
      return this.date.join(" ~ ");
    },
  },
  mounted(){
    Servicio.filtrosCortes().then(respa =>{
      this.filtros=respa.data
    })
    // this.reporte()
  },
  methods: {
    buscarRutero(){
      this.rutero=this.filtros.users.find(x => x.route_id== this.route_id.id)
    },
    buscarRuta(){
      this.route_id = this.filtros.routes.find(x => x.id == this.rutero.route_id)
    },
    onRowSelect() {
      if(this.seleccionado!=null){
        this.modal=true
        
      }else{
          this.onRowUnselect()
      }
    },
    onRowUnselect() {
      this.modal=false
      this.seleccionado = null
      this.reporte()
    },
    reporte(){
      this.loading = true
      this.encabezados = [ 
        { text:  '#', value: 'id'  },
        { text: 'Nombre HSM', value: 'nombre_hsm' }, 
        { text: 'Fecha Envio', value: 'fecha_envio' },
        { text: 'Hora Envio', value: 'hora_envio' },
        { text: 'Total', value: 'total' },
        { text: 'Enviados', value: 'enviados' },
        { text: 'No Enviados', value: 'no_enviados' },
        { text: 'No Entregados', value: 'no_entregados' },
        { text: 'Entregados', value: 'entregados' },
        { text: 'Vistos', value: 'vistos' }
      ]
      var moment = require('moment')
      moment.locale('es')
      if(this.reporteSelected==1){
        // let obj = {start: (moment(this.date[0])>moment(this.date[1]))?this.date[1]:this.date[0], end: this.date[1]===undefined?this.date[0]:(moment(this.date[0])>moment(this.date[1]))?this.date[0]:this.date[1], route_id:this.route_id?this.route_id.id:null, game_machine_id: this.maquina, user_id: this.rutero?this.rutero.id:null}
          Servicio.reporteEnvios((moment(this.date[0])>moment(this.date[1]))?this.date[1]:this.date[0],this.date[1]===undefined?this.date[0]:(moment(this.date[0])>moment(this.date[1]))?this.date[0]:this.date[1])
          .then(respa =>{
            this.datos = respa.result 
          }).finally(()=>{
            this.loading = false;
          })
      }else if(this.reporteSelected==2){
        // let obj = {start: (moment(this.date[0])>moment(this.date[1]))?this.date[1]:this.date[0], end: this.date[1]===undefined?this.date[0]:(moment(this.date[0])>moment(this.date[1]))?this.date[0]:this.date[1], route_id:this.route_id?this.route_id.id:null, game_machine_id: this.maquina, user_id: this.rutero?this.rutero.id:null}
          Servicio.reporteEnviosHemodialisis((moment(this.date[0])>moment(this.date[1]))?this.date[1]:this.date[0],this.date[1]===undefined?this.date[0]:(moment(this.date[0])>moment(this.date[1]))?this.date[0]:this.date[1])
          .then(respa =>{
            this.datos = respa.result 
          }).finally(()=>{
            this.loading = false;
          })
      }else if(this.reporteSelected==3){
        // let obj = {start: (moment(this.date[0])>moment(this.date[1]))?this.date[1]:this.date[0], end: this.date[1]===undefined?this.date[0]:(moment(this.date[0])>moment(this.date[1]))?this.date[0]:this.date[1], route_id:this.route_id?this.route_id.id:null, game_machine_id: this.maquina, user_id: this.rutero?this.rutero.id:null}
          Servicio.reporteEnviosSalvador((moment(this.date[0])>moment(this.date[1]))?this.date[1]:this.date[0],this.date[1]===undefined?this.date[0]:(moment(this.date[0])>moment(this.date[1]))?this.date[0]:this.date[1])
          .then(respa =>{
            this.datos = respa.result 
          }).finally(()=>{
            this.loading = false;
          })
      }else{
        this.loading = false;
      }
     
      
    },
    exportCSV() {
      this.$refs.tablaReporte.exportCSV()
    },
    onRowExpand(event) {
        // this.$toast.add({severity: 'info', summary: 'Row Expanded', detail: 'Vin: ' + event.data.vin, life: 3000});
        console.log(event)
    },
    onRowCollapse(event) {
        // this.$toast.add({severity: 'success', summary: 'Row Collapsed', detail: 'Vin: ' + event.data.vin, life: 3000});
        console.log(event)
    },
    editarReporte(){
      this.seleccionado.total_input_read= parseInt(this.seleccionado.input_read) - (this.seleccionado.input_read_lr),
      this.seleccionado.total_output_read= parseInt(this.seleccionado.output_read) - (this.seleccionado.output_read_lr),
      this.seleccionado.total_markers = parseInt(this.seleccionado.total_input_read) - parseInt(this.seleccionado.total_output_read)
      this.seleccionado.missing = parseInt(this.seleccionado.game_machine_output) - parseInt(this.seleccionado.total_markers)
      Servicio.putReporte(this.seleccionado).then(resp=>{
        if(resp){
          this.seleccionado=resp
          this.modal=false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.v-image__image--cover {
    background-size: contain!important;
  }
</style>
<style lang="postcss">
.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}
.carruselTabla{
  width: 90vw;
  
}
.mapa{
  width: 90vw;
  height: 445px;
}
@media screen and (max-width: 40em) {
  .row /deep/ .p-datatable {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
                    border-bottom: 1px solid var(--surface-d);
                }
            }
        }
    }
}
</style>
